.data-grid-container .data-grid {
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, .125);
} 
.parsedDataTable tr td:last-child{
    width:1%;
    max-width: 100%;
    white-space: nowrap;
}
/* .parsedDataTable td, th {
    padding: 1px !important;
    min-width: 20px;
} */


thead, tbody { display: block; }

tbody {
    max-width: 100%;       /* Just for the demo          */
    overflow-y: auto;    /* Trigger vertical scroll    */
    overflow-x: auto;  /* Hide the horizontal scroll */
}

.action-cell {
    height: 17px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    background-color: unset;
    transition: background-color 500ms ease;
    vertical-align: middle;
    text-align: center;
    border: 1px solid #DDD;
}
tr .cell :not(.action-cell) {
    padding-left: 8px !important;
    padding-right: 8px !important;
}
.expanded {
    border-top: 1px solid rgba(0, 0, 0, .125);
}

/* input.checkbox { 
    width: 40px; 
    height: 40px; 
}  */

.header-map-list .MuiList-padding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.MuiFormControlLabel-root {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
label {
    margin-bottom: 0 !important;
}
.MuiCheckbox-root {
    padding: 4px !important;
}

.firstRowHeaderFormControl .MuiFormControlLabel-root {
    padding-left: 4px !important;
}

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 

}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 

}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 

}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
    /* tbody {
        max-height: 245px;
    } */
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
    /* tbody {
        max-height: 245px;
    }     */
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 
    /* tbody {
        max-height: 445px;
    } */
}
@media (min-width:1367px) { /* hi-res laptops and desktops */ 
    /* tbody {
        max-height: 241px;
    } */
}
@media (min-width:1441px) { /* hi-res laptops and desktops */ 
    /* tbody {
        max-height: 372px;
    } */
}
@media (min-width:1920px) { /* hi-res laptops and desktops */ 
    /* tbody {
        max-height: 552px;
    } */
}
@media (min-width:2261px) { /* hi-res laptops and desktops */ 
    /* tbody {
        max-height: 900px;
    } */
}